@import '../../../styles/colors';
@import '../../../styles/fonts';

.home {
  height: 100vh;
  background-color: $background-color-white;
}

.homecenter {
  background-color: white;
  padding: 15px;
}

.textBox {
  background-color: $background-color-white;
}

.error {
  display: table-cell;
  background-color: $color-red;
  color: $color-white;
  border-radius: 0.4rem;
  vertical-align: middle;
  text-align: center;
  padding: 1rem 0.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  min-height: 6rem;
  margin-top: 2rem;
}

.enterBtn>span {
  margin: 0 auto;
}

.createQuiz,
.hostQuiz {
  color: $color-podium-silver;
  font-size: 2rem;
  margin: 0;
}