body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-logo {
    height: 15vmin;
    pointer-events: none;
  }

  .HeaderLine {
    text-align: center;
    background-color: rgb(47, 84, 150);
    color: white;
  }

  .FooterLine {
    background-color: rgb(47, 84, 150);
    color: white;
  }

  .HeaderLineWithLogo {
    text-align: center;
    background-color: lightgray;
    color: white;
  }

  .CenterBody {
    text-align: center;
    background-color: white;
    color: black;
  }

  .HeaderDiv {
    text-align: center;
    background-color: white;
    color: black;
    width: 250px;
    text-align: center;
  }

  .options {
    background-color: yellowgreen;
    text-align: left;
    padding: 5px;
  }

  .left-aligned {
    text-align: left;
  }

  .white-background {
    background-color: white;
  }

  .right-aligned {
    text-align: right;
  }

  .question {
    background-color: yellow;
    text-align: center;
  }

  .red {
    background-color: rgb(253, 78, 78);
  }

  .blue {
    background-color: rgb(90, 90, 243);
  }

  .orange {
    background-color: rgb(250, 193, 87);
  }

  .green {
    background-color: rgb(128, 255, 128);
  }

  .optionDiv {
    padding: 20px;
  }


  
.home_home__2NoHu {
  height: 100vh;
  background-color: #f2f2f2; }

.home_homecenter__Atd3v {
  background-color: white;
  padding: 15px; }

.JoinGame_home__1-Q-u {
  height: 100vh;
  background-color: #f2f2f2; }

.JoinGame_homecenter__1nKow {
  background-color: white;
  padding: 15px; }

.JoinGame_textBox__3_2pp {
  background-color: #f2f2f2; }

.JoinGame_error__wBkYf {
  display: table-cell;
  background-color: #ff3354;
  color: white;
  border-radius: 0.4rem;
  vertical-align: middle;
  text-align: center;
  padding: 1rem 0.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  min-height: 6rem;
  margin-top: 2rem; }

.JoinGame_enterBtn__1B87d > span {
  margin: 0 auto; }

.JoinGame_createQuiz__34bOm,
.JoinGame_hostQuiz__1Oi3m {
  color: silver;
  font-size: 2rem;
  margin: 0; }

.Quiz_home__YHkOb {
  height: 100vh;
  background-color: #f2f2f2; }

.Quiz_homecenter__1STpp {
  background-color: white;
  padding: 15px; }

.Quiz_textBox__axqSH {
  background-color: #f2f2f2; }

.Quiz_error__1CcA7 {
  display: table-cell;
  background-color: #ff3354;
  color: white;
  border-radius: 0.4rem;
  vertical-align: middle;
  text-align: center;
  padding: 1rem 0.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  min-height: 6rem;
  margin-top: 2rem; }

.Quiz_enterBtn__zth-M > span {
  margin: 0 auto; }

.Quiz_createQuiz__1YnAx,
.Quiz_hostQuiz__3bBqe {
  color: silver;
  font-size: 2rem;
  margin: 0; }

