.App-logo {
    height: 15vmin;
    pointer-events: none;
  }

  .HeaderLine {
    text-align: center;
    background-color: rgb(47, 84, 150);
    color: white;
  }

  .FooterLine {
    background-color: rgb(47, 84, 150);
    color: white;
  }

  .HeaderLineWithLogo {
    text-align: center;
    background-color: lightgray;
    color: white;
  }

  .CenterBody {
    text-align: center;
    background-color: white;
    color: black;
  }

  .HeaderDiv {
    text-align: center;
    background-color: white;
    color: black;
    width: 250px;
    text-align: center;
  }

  .options {
    background-color: yellowgreen;
    text-align: left;
    padding: 5px;
  }

  .left-aligned {
    text-align: left;
  }

  .white-background {
    background-color: white;
  }

  .right-aligned {
    text-align: right;
  }

  .question {
    background-color: yellow;
    text-align: center;
  }

  .red {
    background-color: rgb(253, 78, 78);
  }

  .blue {
    background-color: rgb(90, 90, 243);
  }

  .orange {
    background-color: rgb(250, 193, 87);
  }

  .green {
    background-color: rgb(128, 255, 128);
  }

  .optionDiv {
    padding: 20px;
  }


  