@import '../../styles/colors';
@import '../../styles/fonts';

.home {
  height: 100vh;
  background-color: $background-color-white;
}

.homecenter {
  background-color: white;
  padding: 15px;
}

