$background-color-aqua: rgb(65, 196, 222);
$background-color-blue: rgb(69, 163, 229);
$background-color-grey: rgb(51, 51, 51);
$background-color-orange: rgb(247, 142, 86);
$background-color-dark-purple: rgb(103, 58, 183);
$background-color-light-purple: rgb(138, 74, 243);
$background-color-yellow: rgb(243, 211, 51);
$background-color-white: rgb(242, 242, 242);
$background-transition-color-blue: rgb(65, 196, 222);
$background-transition-color-green: rgb(140, 199, 63);
$background-transition-color-yellow: rgb(243, 211, 51);
$background-transition-color-orange: rgb(247, 142, 86);

$color-black: rgb(0, 0, 0);
$color-purple: rgb(69, 24, 143);
$color-white: rgb(255, 255, 255);
$color-red: rgb(255, 51, 84);
$color-light-green: rgb(102, 191, 57);

$color-correct: rgb(138, 195, 74);
$color-incorrect: rgb(244, 67, 54);

$color-answer-red: rgb(244, 67, 54);
$color-answer-blue: rgb(63, 81, 181);
$color-answer-orange: rgb(255, 152, 0);
$color-answer-green: rgb(76, 175, 80);

$color-podium-purple: rgb(134 , 75, 191);
$color-podium-bronze: rgb(205, 127, 50);
$color-podium-gold: rgb(255, 215, 0);
$color-podium-silver: rgb(192, 192, 192);
